import { Row, Col, Input, Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import {
  addRegion,
  editRegion,
  fetchRegion,
} from "../../stores/api_calls/locations";
import "./location.scss";
import { error, success } from "../../contants/snackbars";
import {
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import { IoMdAddCircleOutline , IoMdCloseCircle} from 'react-icons/io';
import { colorScheme } from '../../contants/variables';
import { useLocation, useNavigate } from 'react-router-dom';
import Kjur from '../../stores/utils/jwt'
import { validateRegionForm } from "../../stores/helpers/locationFormValidation";

const RegionForm = () => {
  
  const [isEditing, setIsEditing] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [fetchLoading , setFetchLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const [region, setRegion] = useState({});
  const [form, setForm] = useState({
    regionName: "",
  });

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  useEffect(() => {
    if (location.pathname.includes("/edit")) {
      setIsEditing(true);
      const parsedData = Kjur.decode(params.get("token"));
      setFetchLoading(true)
      const getRegion = fetchRegion(parsedData?.id);
      getRegion.then((res) => {
        if(res.data.success){
          setRegion(res.data.data)
          setFetchLoading(false)
        }
      }).catch((e)=>console.log(e))
    }
  }, []);

  useEffect(()=>{
    if(!fetchLoading && isEditing) {
        const parsedData = Kjur.decode(params.get('token'))

        setForm({
          ...form,
          id: parsedData.id,
          regionName: parsedData.region_name,
        })
    }
  }, [fetchLoading])

  const onSubmit = () => {
    setRequestLoading(true);

    const createRegion = isEditing
      ? editRegion(form)
      : addRegion(form);

    createRegion
      .then((res) => {
        if (res?.data?.success) {
          success(
            `Region ${isEditing ? "updated" : "added"} successfully`
          );
          setRequestLoading(false);
          navigate("../locations/regions", { replace: true });
        }
      })
      .catch((e) => {
        setRequestLoading(false);
        error(e.response.data.message);
      });
  };

  const onCancel  = () => {
    navigate("../locations/regions", { replace: true })
  }

  return (
    <div className="accessory-form-container">
      <div className={`table-title-icon ${!isEditing ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEditing ? "add" : "edit"}`}>
        {!isEditing ? "Create Region" : `Edit Region`}
      </p>

      <Row>
        <Col span={12}>
          <div className="form-fields">
            <p className="form-field-label">
              Region Name <span>*</span> :{" "}
            </p>
            <Input
              value={form.regionName}
              onChange={(text) => handleFieldChange("regionName", text)}
              placeholder="Region Name"
            />
          </div>
        </Col>
      </Row>

      <div className="btn-div">
        <Button
        color="default" 
        variant="solid"
        size="large"
        onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <div className="btn">
          {requestLoading ? (
            <LoadingButton />
          ) : (
            <PrimaryButton
              label={isEditing ? "Update Region" : "Create Region"}
              color={colorScheme.primary}
              onClick={() => onSubmit()}
              disabled={!validateRegionForm(form)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegionForm;