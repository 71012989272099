export const validateRegionForm = (data) =>{

    const requiredFields = [
        "regionName",
    ]

    var validFieldCount = 0
    
    requiredFields.forEach((field)=>{
        if(data[field]){
            validFieldCount = validFieldCount + 1
        }else{
            // console.log(field , false)
        }
    })

    if(validFieldCount===requiredFields.length){
        return true
    }else{
        return false
    }
}

export const validateProvinceForm = (data) =>{

    const requiredFields = [
        "provinceName",
        "regionId",
    ]

    var validFieldCount = 0
    
    requiredFields.forEach((field)=>{
        if(data[field]){
            validFieldCount = validFieldCount + 1
        }else{
            // console.log(field , false)
        }
    })

    if(validFieldCount===requiredFields.length){
        return true
    }else{
        return false
    }
}

export const validateAreaForm = (data) =>{

    const requiredFields = [
        "areaName",
        "regionId",
        "provinceId",
    ]

    var validFieldCount = 0
    
    requiredFields.forEach((field)=>{
        if(data[field]){
            validFieldCount = validFieldCount + 1
        }else{
            // console.log(field , false)
        }
    })

    if(validFieldCount===requiredFields.length){
        return true
    }else{
        return false
    }
}