import React from 'react'
import './carform.scss'
import {FaStar} from 'react-icons/fa'
import { Col, Divider, Input, Row, Tabs } from 'antd';
import {MdOutlineArrowBackIos , MdOutlineArrowForwardIos} from 'react-icons/md';
import {FaCalendarDay , FaTachometerAlt , FaCarCrash ,FaCar, FaUsers} from 'react-icons/fa';
import {GiGearStickPattern , GiGears} from 'react-icons/gi';
import {IoMdPin} from'react-icons/io';

export const WebView = (props) => {

    const {form , localImageUrls} = props
   
    return (
        <div className='web-view-container'>

            <Row>
                <Col span={16}>
                    <div className='product-container'>
                        <div className='image-container'>
                            <img src={form.imageUrls.length? form.imageUrls[0].metadata?.image_url : localImageUrls[0]}/>
                        </div>

                        <div className='header-name-container'>
                            <div>
                                <p className='type'>{form.vehicleType}</p>
                                <p className='name'>{form.carName}</p>
                            </div>
                            <div>
                                <div className='slider-icon-container'>
                                    <div className='slider-icon'>
                                        <MdOutlineArrowBackIos/>
                                    </div>
                                    <div className='slider-icon'>
                                        <MdOutlineArrowForwardIos/>
                                    </div>
                                </div>

                                <p className='price'>S$ {form.askingPrice?.toLocaleString()}</p>
                            </div>
                        </div>

                        <div className='overview'>
                            <p className='header-section'>OVERVIEW</p>
                            <p>{form.description}</p>
                        </div>

                        <Divider/>

                        <div className='overview'>
                            <p className='header-section'>TECHNICAL SPECIFICATION</p>
                            <Row gutter={[10,10]} type="flex">
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaCalendarDay/>
                                        <div>
                                            <p className='tech-spec-label'>MFR. DATE</p>
                                            <p className='tech-spec-value'>{form.manufacturedYear}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaTachometerAlt/>
                                        <div>
                                            <p className='tech-spec-label'>MILEAGE</p>
                                            <p className='tech-spec-value'>{form.mileage.toLocaleString()}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <GiGearStickPattern/>
                                        <div>
                                            <p className='tech-spec-label'>TRANSMISSION</p>
                                            <p className='tech-spec-value'>{form.transmission}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <GiGears/>
                                        <div>
                                            <p className='tech-spec-label'>ENGINE CAP</p>
                                            <p className='tech-spec-value'>{form.engineCap.toLocaleString()}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaCar/>
                                        <div>
                                            <p className='tech-spec-label'>BODY TYPE</p>
                                            <p className='tech-spec-value'>{form.vehicleType}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaCarCrash/>
                                        <div>
                                            <p className='tech-spec-label'>CONDITION</p>
                                            <p className='tech-spec-value'>{form.condition}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={16}>
                                    <div className='tech-specs-container'>
                                        <IoMdPin/>
                                        <div>
                                            <p className='tech-spec-label'>LOCATION</p>
                                            <p className='tech-spec-value'>{form.cityName} {form.regionName}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div className='tech-specs-container'>
                                        <FaUsers/>
                                        <div>
                                            <p className='tech-spec-label'>OWNERS</p>
                                            <p className='tech-spec-value'>{form.numberOfOwners}</p>
                                        </div>
                                    </div>
                                </Col>
                                
                            </Row>
                        </div>

                        <Divider/>

                        <div className='overview'>
                            <p className='header-section'>FEATURES</p>
                            <p>{form.features}</p>
                        </div>

                        <Divider/>

                        <div className='overview'>
                            <p className='header-section'>ACCESSORIES</p>
                            <p>{form.accessories}</p>
                        </div>

                        <Divider/>

                        <div className='review-container'>
                            <p className='header-section'>SUBMIT YOUR REVIEWS</p>

                            <Input placeholder='Add your review to this car'/>

                            <p className='comments'>0 comments</p>
                        </div>

                    </div>
                </Col>
                <Col span={8}>
                    <div className='sides-container'>
                        <div className='side-div'>
                            <p>DEALER INFO</p>
                        </div>
                        <div className='side-div'>
                            <p>DEALER DIRECT CONTACT</p>
                        </div>
                        <div className='side-div'>
                            <p>LOAN CALCULATOR</p>
                        </div>
                        <div className='side-div'>
                            <p>ADS</p>
                        </div>
                    </div>
                </Col>
            </Row>
            
        </div>
    )
}

export const MobileView = (props) => {

    const {form , localImageUrls} = props
    // console.log(data)
    return (
        <div className='mobile-view-container'>
            <div className='image-container'>
                <img src={form.imageUrls.length? form.imageUrls[0].metadata?.image_url : localImageUrls[0]}/>
            </div>
            <div className='header-name-container'>
                <div>
                    <p className='name'>{form.carName}</p>
                    <p className='price'>S$ {form.askingPrice}</p>
                </div>
                <FaStar/>
            </div>

            <div className='technical-specs-container'>
                <Row>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaCalendarDay/>
                            <p>Mfr. Date : {form.manufacturedYear}</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaTachometerAlt/>
                            <p>Mileage : {form.mileage}</p>
                        </div>
                    </Col>
                </Row>
                <Divider className='divider'/>
                <Row>
                    <Col span={12}>
                        <div className='spec-container'>
                            <GiGearStickPattern/>
                            <p>{form.transmission}</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='spec-container'>
                            <GiGears/>
                            <p>Engine Cap : {form.engineCap}</p>
                        </div>
                    </Col>
                </Row>
                <Divider className='divider'/>
                <Row>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaCar/>
                            <p>Type : {form.vehicleType}</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaCarCrash/>
                            <p>Condition : {form.condition}</p>
                        </div>
                    </Col>
                </Row>
                <Divider className='divider'/>
                <Row>
                    <Col span={12}>
                        <div className='spec-container'>
                            <IoMdPin/>
                            <p>{form.cityName}, {form.regionName}</p>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='spec-container'>
                            <FaUsers/>
                            <p>No. of owners : {form.numberOfOwners}</p>
                        </div>
                    </Col>
                </Row>
                <Divider className='divider'/>
            </div>

            <div className='details-container'>
                <Tabs centered>
                    <Tabs.TabPane
                        tab="Description" key="1"
                    >
                        <p>{form.description}</p>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Features" key="2"
                    >
                        <p>{form.features}</p>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab="Accessories" key="3"
                    >
                        <p>{form.accessories}</p>
                    </Tabs.TabPane>
                </Tabs>
            </div>

            <div className='review-container'>
                <p className='review-title'>Reviews</p>

                <Input placeholder='Add your review to this car'/>

                <p className='comments'>0 comments</p>
            </div>

        </div>
    )
}


