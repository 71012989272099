import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./navigation.scss";
import {
  MdDashboard,
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdCarRental,
  MdWork,
  MdCarRepair,
  MdEmail,
} from "react-icons/md";
import { BsNewspaper } from "react-icons/bs";
import {
  FaBuysellads,
  FaUserCog,
  FaUserTag,
  FaCar,
  FaTags,
  FaQuestionCircle,
  FaComment,
} from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GiTyre } from "react-icons/gi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { ImBriefcase } from "react-icons/im";
import { BiPackage } from "react-icons/bi";
import { RiSendPlaneFill } from "react-icons/ri";
import { AiOutlineFieldNumber, AiFillDollarCircle, AiFillBank } from "react-icons/ai";
import { IoIosSettings } from "react-icons/io";
import { colorScheme } from "../contants/variables";
import { UserContext } from "../stores/contexts/userContext";

const LeftSideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [currentActiveMenu, setCurrentActiveMenu] = useState("");
  const [toggleMenuLink, setToggleMenuLink] = useState("");
  const [userConfig] = useContext(UserContext);
  const userPermissions = userConfig.userDetails.user_permissions;

  useEffect(() => {
    if (location.pathname.includes("/edit")) {
      setIsEdit(true);
      redirect();
    } else {
      setIsEdit(false);
      redirect();
    }
  }, [location.pathname]);

  const redirect = () => {
    routes.forEach((el) => {
      if (location.pathname.includes(el.url) && !el.show) {
        navigate("../no-access", { replace: true });
      }
    });
  };

  const routes = [
    { url: "dashboard", label: "Dashboard", Icon: <MdDashboard />, show: true },
    // {
    //   url: "car-bids",
    //   label: "Manage CarBids",
    //   Icon: <FaCar />,
    //   show: userPermissions.includes("car_ads_management"),
    //   subLinks: [
    //     { url: "verified", label: "Verified CarBids" },
    //     { url: "unverified", label: "Unverified CarBids" },
    //     { url: "sold", label: "Sold CarBids" },
    //     {
    //       url: isEdit ? "edit" : "create",
    //       label: isEdit ? "Edit CarBids" : "Add CarBids",
    //     },
    //   ],
    // },
    {
      url: "cars",
      label: "Manage Cars",
      Icon: <FaCar />,
      show: userPermissions.includes("car_ads_management"),
      subLinks: [
        { url: "verified", label: "Verified Used Cars" },
        { url: "unverified", label: "Unverified Used Cars" },
        { url: "verified-commercial", label: "Verified Commercial Cars" },
        { url: "unverified-commercial", label: "Unverified Commercial Cars" },
        { url: "verified-new", label: "Verified New Cars" },
        { url: "unverified-new", label: "Unverified New Cars" },
        { url: "sold", label: "Sold Cars" },
        { url: "draft", label: "Draft Cars" },
        {
          url: isEdit ? "edit" : "create",
          label: isEdit ? "Edit Used Car" : "Add Used Car",
        },
      ],
    },
    {
      url: "rentals",
      label: "Manage Rentals",
      Icon: <MdCarRental />,
      show: userPermissions.includes("car_rental"),
      subLinks: [
        { url: "verified", label: "Verified Cars" },
        { url: "unverified", label: "Unverified Cars" },
        {
          url: isEdit ? "edit" : "create",
          label: isEdit ? "Edit Ad" : "Create Ad",
        },
      ],
    },
    {
      url: "car-accessories",
      label: "Manage Accessories",
      Icon: <GiTyre />,
      show: userPermissions.includes("accessories_listing"),
      subLinks: [
        { url: "verified", label: "Verified Accessories" },
        { url: "unverified", label: "Unverified Accessories" },
        { url: "sold", label: "Sold Accessories" },
        { url: "draft", label: "Draft Accessories" },
        {
          url: isEdit ? "edit" : "create",
          label: isEdit ? "Edit Accessories" : "Add Accessories",
        },
      ],
    },
    // {
    //   url: "brands",
    //   label: "Manage Brands",
    //   Icon: <FaTags />,
    //   show: userPermissions.includes("brand_management"),
    // },
    {
      url: "ads",
      label: "Manage Banner",
      Icon: <FaBuysellads />,
      show: userPermissions.includes("banner_management"),
    },
    {
      url: "dealers",
      label: "Dealers",
      Icon: <FaUserTag />,
      show: userPermissions.includes("dealer_management"),
    },
    {
      url: "direct-sellers",
      label: "Direct sellers",
      Icon: <FaUserTag />,
      show: userPermissions.includes("private_seller_management"),
    },
    // {
    //   url: "bidding-dealers",
    //   label: "Bidding Dealers",
    //   Icon: <FaUserTag />,
    //   show: userPermissions.includes("dealer_management"),
    // },
    // {
    //   url: "bidding-direct-sellers",
    //   label: "Sell By Bidding",
    //   Icon: <FaUserTag />,
    //   show: userPermissions.includes("private_seller_management"),
    // },
    // {
    //   url: "bidding-comments",
    //   label: "Bidding Comment",
    //   Icon: <FaComment />,
    //   show: userPermissions.includes("dealer_management"),
    //   subLinks: [
    //     { url: "verified", label: "Verified Comment" },
    //     { url: "unverified", label: "Unverified Comment" },
    //     { url: "verified-bid-price", label: "Verified Bid Price" },
    //     { url: "unverified-bid-price", label: "Unverified Bid Price" },
    //   ],
    // },
    {
      url: "users",
      label: "Manage Users",
      Icon: <FaUserCog />,
      show: userPermissions.includes("user_management"),
    },
    {
      url: "marketing-emails",
      label: "Manage Emails",
      Icon: <MdEmail />,
      show: userPermissions.includes("dealer_management"),
      subLinks: [
        { url: "verified", label: "Verified Emails" },
        { url: "unverified", label: "Unverified Emails" },
      ],
    },
    // {
    //   url: "articles",
    //   label: "Manage Articles",
    //   Icon: <BsNewspaper />,
    //   show: userPermissions.includes("article_management"),
    // },
    // {
    //   url: "packages",
    //   label: "Manage Packages",
    //   Icon: <BiPackage />,
    //   show: userPermissions.includes("package_management"),
    // },
    {
      url: "locations",
      label: "Manage Locations",
      Icon: <FaLocationDot />,
      show: userPermissions.includes("article_management"),
      subLinks: [
        { url: "regions", label: "Regions" },
        { url: "provinces", label: "Provinces" },
        { url: "areas", label: "Areas" },
      ],
    },
    {
      url: "cms",
      label: "Manage CMS",
      Icon: <IoIosSettings />,
      show: userPermissions.includes("article_management"),
      subLinks: [
        { url: "announcement", label: "Announcements" },
        { url: "articles", label: "Articles" },
        { url: "plate-numbers", label: "Plate Numbers" },
        { url: "car-workshops", label: "Car Workshops" },
        { url: "brands", label: "Brands" },
        { url: "packages", label: "Packages" },
        { url: "insurances", label: "Insurances" },
        { url: "about", label: "About Company" },
        { url: "careers", label: "Careers" },
        { url: "faq", label: "FAQ" },
      ],
    },
    // {
    //   url: "careers",
    //   label: "Careers",
    //   Icon: <ImBriefcase />,
    //   show: userPermissions.includes("career_management"),
    // },
    // {
    //   url: "plate-numbers",
    //   label: "Plate Numbers",
    //   Icon: <AiOutlineFieldNumber />,
    //   show: userPermissions.includes("plate_number_management"),
    // },
    // {
    //   url: "car-workshops",
    //   label: "Car Workshops",
    //   Icon: <MdCarRepair />,
    //   show: true,
    // },
    // {
    //   url: "coe",
    //   label: "Manage COE",
    //   Icon: <AiFillDollarCircle />,
    //   show: userPermissions.includes("coe_management"),
    // },
    {
      url: "banks",
      label: "Manage Banks",
      Icon: <AiFillBank />,
      show: userPermissions.includes("bank_management"),
    },
    // {
    //   url: "insurances",
    //   label: "Manage Insurances",
    //   Icon: <AiFillBank />,
    //   show: userPermissions.includes("insurance_management"),
    // },
    // {
    //   url: "faq",
    //   label: "Manage FAQ",
    //   Icon: <FaQuestionCircle />,
    //   show: userPermissions.includes("faq_management"),
    // },
    // {
    //   url: "about",
    //   label: "Manage About Company",
    //   Icon: <FaQuestionCircle />,
    //   show: userPermissions.includes("about_management"),
    // },
    // {
    //   url: "announcement",
    //   label: "Announcements",
    //   Icon: <HiOutlineSpeakerphone />,
    //   show: userPermissions.includes("announcement"),
    // },
    {
      url: "trigger",
      label: "Trigger SMS",
      Icon: <RiSendPlaneFill />,
      show: userPermissions.includes("trigger_sms"),
    },
  ];

  useEffect(() => {
    if (location.pathname.includes("/cars")) {
      setCurrentActiveMenu('cars');
      setToggleMenuLink('cars');
    } else if(location.pathname.includes("/car-bids")) {
      setCurrentActiveMenu('car-bids');
      setToggleMenuLink('car-bids');
    } else if(location.pathname.includes("/ads")) {
      setCurrentActiveMenu('ads');
      setToggleMenuLink('ads');
    } else if(location.pathname.includes("/bidding-comments")) {
      setCurrentActiveMenu('bidding-comments');
      setToggleMenuLink('bidding-comments');
    } else if(location.pathname.includes("/marketing-emails")) {
      setCurrentActiveMenu('marketing-emails');
      setToggleMenuLink('marketing-emails');
    } else if(location.pathname.includes("/rentals")) {
      setCurrentActiveMenu('rentals');
      setToggleMenuLink('rentals');
    } else if(location.pathname.includes("/car-accessories")) {
      setCurrentActiveMenu('car-accessories');
      setToggleMenuLink('car-accessories');
    } else if(location.pathname.includes("/locations")) {
      setCurrentActiveMenu('locations');
      setToggleMenuLink('locations');
    } else if(location.pathname.includes("/cms")) {
      setCurrentActiveMenu('cms');
      setToggleMenuLink('cms');
    } else {
      setCurrentActiveMenu('');
      setToggleMenuLink('');
    }
  }, [location.pathname]);

  return (
    <div className="nav-body">
      <div style={{ width: "90%", margin: "0 auto" }}>
        <img src={logo} width="100%" />
      </div>
      <div className="nav-container">
        {
          routes.filter(menu => menu.show).map((route, index) => 
            <div key={index} className={`menu-item-wrapper ${toggleMenuLink === route.url ? "open" : ""}`}>
              {
                route.subLinks ?
                <React.Fragment>
                    <div
                        className={`navlink-cars-container ${
                          currentActiveMenu.includes(route.url) ? "active" : ""
                        }`}
                        onClick={() => {
                            toggleMenuLink === route.url ? setToggleMenuLink("") : setToggleMenuLink(route.url)
                        }}
                    >
                        {route.Icon}
                        <p>{route.label}</p>
                        {toggleMenuLink === route.url ? (
                        <MdKeyboardArrowDown style={{marginRight: -7}} />
                        ) : (
                        <MdKeyboardArrowRight style={{marginRight: -7}} />
                        )}
                    </div>
                    {
                      toggleMenuLink === route.url &&
                        <div className="sub-link-container">
                          {
                            route.subLinks.map((subLink, i) => (
                              <NavLink
                                  key={i}
                                  className="sub-link"
                                  // exact
                                  to={`/${route.url}/${subLink.url}`}
                              >
                                <div className="nav-sub-link-container">
                                  <p>{subLink.label}</p>
                                </div>
                              </NavLink>
                            ))
                          }
                        </div>
                    }
                </React.Fragment>
                :
                <NavLink
                    className="normal"
                    // exact
                    to={route.url}
                    key={index}
                    >
                    <div className="navlink-container">
                        {route.Icon}
                        <p>{route.label}</p>
                    </div>
                </NavLink>
              }
            </div>
          )
        }
      </div>
      <p
        style={{
          textAlign: "center"
        }}
      >
        Version 2024.12.12.1
      </p>
    </div>
  );
};

export default LeftSideMenu;
