import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchRegions = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    sort_by: "desc",
    sort_order: "date_created",
    keyword: data.keyword,
  };
  
  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/regions?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchRegion = async (id) => {
  const data = {
    id : id
  }

  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/regions/show?token=${encodedData}`,
    axiosConfig
  );
};

export const addRegion = async (payload) => {
  
  const data = {
    region_name: payload.regionName,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/regions`,
    { token: encodedData },
    axiosConfig
  );
};

export const editRegion = async (payload) => {
    const data = {
        id: payload.id,
        region_name: payload.regionName,
      };
    
      const encodedData = Kjur.encode(data);
    
      return await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/regions/update`,
        { token: encodedData },
        axiosConfig
      );
};

export const deleteRegion = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/regions/delete?token=${encodedData}`,
    axiosConfig
  );
};

// Provinces --------------------------
export const fetchProvinces = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    sort_by: "desc",
    sort_order: "date_created",
    keyword: data.keyword,
  };
  
  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/provinces?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchProvince = async (id) => {
  const data = {
    id : id
  }

  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/provinces/show?token=${encodedData}`,
    axiosConfig
  );
};

export const addProvince = async (payload) => {
  
  const data = {
    province_name: payload.provinceName,
    region_id: payload.regionId,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/provinces`,
    { token: encodedData },
    axiosConfig
  );
};

export const editProvince = async (payload) => {
    const data = {
        id: payload.id,
        province_name: payload.provinceName,
        region_id: payload.regionId,
      };
    
      const encodedData = Kjur.encode(data);
    
      return await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/provinces/update`,
        { token: encodedData },
        axiosConfig
      );
};

export const deleteProvince = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/provinces/delete?token=${encodedData}`,
    axiosConfig
  );
};


// Areas --------------------------
export const fetchAreas = async (data) => {
  const obj = {
    page: data.page,
    limit: data.limit,
    sort_by: "desc",
    sort_order: "date_created",
    keyword: data.keyword,
  };
  
  const encodedData = Kjur.encode(obj);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/areas?token=${encodedData}`,
    axiosConfig
  );
};

export const fetchArea = async (id) => {
  const data = {
    id : id
  }

  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/areas/show?token=${encodedData}`,
    axiosConfig
  );
};

export const addArea = async (payload) => {
  
  const data = {
    area_name: payload.areaName,
    region_id: payload.regionId,
    province_id: payload.provinceId,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/areas`,
    { token: encodedData },
    axiosConfig
  );
};

export const editArea = async (payload) => {
      const data = {
        id: payload.id,
        area_name: payload.areaName,
        region_id: payload.regionId,
        province_id: payload.provinceId,
      };
    
      const encodedData = Kjur.encode(data);
    
      return await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/admin/areas/update`,
        { token: encodedData },
        axiosConfig
      );
};

export const deleteArea = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/areas/delete?token=${encodedData}`,
    axiosConfig
  );
};