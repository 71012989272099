import React , {useState , useEffect} from 'react'
import { Table , Input, Spin} from 'antd';
import './region.scss'
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import moment from 'moment'
import {FaBuysellads} from 'react-icons/fa'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import {MdAddCircle} from 'react-icons/md';
import { colorScheme } from '../contants/variables';
import { fetchRegions, deleteRegion } from '../stores/api_calls/locations';
import { error, success } from '../contants/snackbars';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import Kjur from '../stores/utils/jwt'

const RegionIndex = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState([])
    const [isEdit , setIsEdit] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({
        showDelete:false , 
        showForm:false , 
        showPreview:false ,
        data:{}
    })
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:10,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const columns = [
        {
            title: 'Region Name',
            dataIndex: 'regionName',
            // sorter: true,
            render: (text ,record) => `${record.region_name}`,
        },
        {
            title: "Date Created",
            dataIndex: "dateCreated",
            // sorter: true,
            render: (text, record) =>
              moment.utc(record.date_created).local().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title:'Actions',
            dataIndex:'actions',
            render:(text,record)=>(
                <div className='actions-icons-container'>
                    <IconButton className='icon-button edit' tootTipTitle="Edit Region" Icon={<RiEdit2Fill/>} onClick={()=>onEdit(record)} />
                    <IconButton className='icon-button delete' tootTipTitle="Delete Region" Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(record)}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])

    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch,
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {
        setIsLoading(true)
        const regions = fetchRegions(data)
        regions.then((res)=>{
            if(res.data.success){
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch,
            }
            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onDeleteSubmit = () => {
        const removeRegion = deleteRegion(showModal.data.id)
        removeRegion.then((res)=>{
            if(res.data.success){
                success("Regions Deleted Successfully")
                setShowModal({...showModal , showDelete:false , data:{}})
                refetchLists()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    // const onOpenForm = (action , record) => {
    //     if(action==="add"){
    //         setIsEdit(false)
    //         setShowModal({...showModal , showForm:true})
    //     }else{
    //         setIsEdit(true)
    //         setShowModal({...showModal , showForm:true , data:record})
    //     }
    // }

    const onAdd  = () => {
        navigate("../locations/regions/create", { replace: true })
    }

    const onEdit = (record) => {
        const token = Kjur.encode(record);
        navigate(
            {
                pathname:'../locations/regions/edit',
                search:`?${createSearchParams({token:token})}`,
            },
            {replace:true}
        )
    }

    // const onSubmitForm = () => {
    //     setShowModal({...showModal , showForm:false , data:{}})
    // }

    return (
        <div className='banner-table-container'>
            <div className='table-title-icon primary'>
                <FaBuysellads/>
            </div>
            <p className='table-title-text primary'>Regions</p>

            <div
                className='search-container'
            >
                
                <div className='add-button-container'>
                    <PrimaryButton 
                        Icon={<MdAddCircle/>} 
                        color={colorScheme.primary} 
                        label="Create Region" 
                        onClick={()=>onAdd()}
                    />
                </div>

                <Input
                    style={{width:300}}
                    size='large'
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onPressEnter={onEnter}
                />
            </div>
            
            <div className='table-div'>
                <Table
                    columns={columns}
                    rowKey={record => record.id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>
            
            <CustomDeleteModal
                title="Delete Region"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.region_name}` , type:"region"}}
            />

        </div>
    )
}

export default RegionIndex;
