import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Col, Row } from "antd";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import "./App.scss";
import LeftSideMenu from "./components/navigation/LeftSideMenu";
import DashboardIndex from "./components/dashboard/DashboardIndex";
import { UserContext } from "./components/stores/contexts/userContext";
import Login from "./components/auth/Login";
import UserChips from "./components/auth/UserChips";
import UnverifiedCarLists from "./components/lists_cars/UnverifiedCarLists";
import VerifiedCarLists from "./components/lists_cars/VerifiedCarLists";
import CarFormIndex from "./components/forms/cars/CarFormIndex";
import SoldCarLists from "./components/lists_cars/SoldCarLists";
import BannerLists from "./components/lists_banners/BannerIndex";
import AdLists from "./components/lists_ads/AdLists";
import LandingAdLists from "./components/lists_ads/LandingAdLists";
import ProductAdLists from "./components/lists_ads/ProductAdLists";
import OtherAdLists from "./components/lists_ads/OtherAdLists";
import UserLists from "./components/lists_users/UserIndex";
import DealerLists from "./components/lists_dealers/DealerIndex";
import PackagesIndex from "./components/lists_packages/PackagesIndex";
import BrandIndex from "./components/lists_brands/BrandIndex";
import ArticleIndex from "./components/lists_articles/ArticleIndex";
import ArticleForm from "./components/forms/articles/ArticleForm";
import ForgotPassword from "./components/auth/ForgotPassword";
import {
  NoAccessPage,
  NotFoundPage,
} from "./components/splashpages/SplashPages";
import Profile from "./components/profile/Profile";
import CarRentalIndex from "./components/lists_car_rental/CarRentalIndex";
import VerifiedRentalLists from "./components/lists_car_rental/VerifiedRentalLists";
import UnverifiedRentalLists from "./components/lists_car_rental/UnverifiedRentalLists";
import ProductRental from "./components/forms/rentals/ProductRental";
import Trigger from "./components/trigger_page/Trigger";
import IdleTimer from "react-idle-timer";
import Countdown from "react-countdown";
import CountDownModal from "./components/custom_components/countdownModal";
import CareersIndex from "./components/careers/CareersIndex";
import CareerForm from "./components/forms/careers/CareerForm";
import PlateNumberList from "./components/lists_plate_numbers/PlateNumbersIndex";
import CoeIndex from "./components/coe/CoeIndex";
import FaqIndex from "./components/faq/FaqIndex";
import AboutIndex from "./components/about/AboutIndex";
import CoeForm from "./components/forms/coe_v2/CoeForm";
import BankIndex from "./components/lists_banks/BankIndex";
import InsuranceIndex from "./components/lists_insurances/InsuranceIndex";
import CarWorkShops from "./components/car-workshops/CarWorkshopsIndex";
import CarWorkshopForm from "./components/forms/car-workshops/CarWorkshopForm";
import Announcement from "./components/announcement/announcement";
import UnverifiedNewCarLists from "./components/lists_cars/UnverifiedNewCarLists";
import VerifiedNewCarLists from "./components/lists_cars/VerifiedNewCarLists";
import NewCarFormIndex from "./components/forms/cars/NewCarFormIndex";

import VerifiedCarBidLists from "./components/lists_car_bids/VerifiedCarBidLists";
import UnverifiedCarBidLists from "./components/lists_car_bids/UnverifiedCarBidLists";
import CarBidFormIndex from "./components/forms/car-bids/CarBidFormIndex";
import SoldCarBidLists from "./components/lists_car_bids/SoldCarBidLists";
import BiddingDealerLists from "./components/lists_bidding_dealers/BiddingDealerIndex";
import CommentLists from "./components/lists_bidding_comments/CommentLists";
import UnverifiedCommentLists from "./components/lists_bidding_comments/UnverifiedCommentLists";
import BiddingPriceList from "./components/lists_bidding_comments/BiddingPriceList";
import UnverifiedBiddingPriceList from "./components/lists_bidding_comments/UnverifiedBiddingPriceList";
import MarketingEmailList from "./components/lists_marketing_emails/MarketingEmailIndex";
import UnverifiedEmailList from "./components/lists_marketing_emails/UnverifiedEmailIndex";
import VerifiedCommercialLists from "./components/lists_cars/VerifiedCommercialLists";
import UnverifiedCommercialLists from "./components/lists_cars/UnverifiedCommercialLists";
import DraftCarLists from "./components/lists_cars/DraftCarLists";
import VerifiedAccessoriesList from "./components/lists_accessories/VerifiedAccessoriesList";
import UnverifiedAccessoriesList from "./components/lists_accessories/UnverifiedAccessoriesList";
import DraftAccessoriesList from "./components/lists_accessories/DraftAccessoriesList";
import SoldAccessoriesList from "./components/lists_accessories/SoldAccessoriesList";
import AccessoryForm from "./components/forms/accessories/AccessoryForm";

import RegionIndex from "./components/lists_regions/RegionIndex";
import RegionForm from "./components/forms/locations/RegionForm";
import ProvinceIndex from "./components/lists_provinces/ProvinceIndex";
import ProvinceForm from "./components/forms/locations/ProvinceForm";
import AreaIndex from "./components/lists_areas/AreaIndex";
import AreaForm from "./components/forms/locations/AreaForm";

function App() {
  let navigate = useNavigate();

  const [userConfig, setUserConfig] = useContext(UserContext);
  const [startTimer, setStartTimer] = useState(false);
  const idleTimer = useRef(null);

  return (
    <div
      className="content-container"
    >
      {userConfig.isLoggedIn ? (
        <div className="side-nav-container">
          <LeftSideMenu />
        </div>
      ) : null}

      {!userConfig.isLoggedIn ? (
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          <Route path="/login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route
            path="*"
            element={
              <div>
                NO ACCESS{" "}
                <Button onClick={() => navigate("../", { replace: true })}>
                  LOGIN
                </Button>
              </div>
            }
          />
        </Routes>
      ) : userConfig.isLoggedIn ? (
        <div className="main-container" style={{ paddingBottom: "20px" }}>
          {userConfig.isLoggedIn ? <UserChips /> : null}
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/dashboard" replace={true} />}
            />
            <Route
              path="/login"
              element={<Navigate to="/dashboard" replace={true} />}
            />

            <Route path="dashboard" element={<DashboardIndex />} />
            <Route path="profile" element={<Profile />} />

            <Route path="cars/verified" element={<VerifiedCarLists />} />
            <Route path="cars/verified-commercial" element={<VerifiedCommercialLists />} />
            <Route path="cars/verified-new" element={<VerifiedNewCarLists />} />
            <Route path="cars/unverified" element={<UnverifiedCarLists />} />
            <Route
              path="cars/unverified-commercial"
              element={<UnverifiedCommercialLists />}
            />
            <Route
              path="cars/unverified-new"
              element={<UnverifiedNewCarLists />}
            />
            <Route path="cars/sold" element={<SoldCarLists />} />
            <Route path="cars/draft" element={<DraftCarLists />} />

            <Route path="cars/create" element={<CarFormIndex />} />
            <Route path="cars/edit" element={<CarFormIndex />} />
            <Route path="cars/create-new" element={<NewCarFormIndex />} />
            <Route path="cars/edit-new" element={<NewCarFormIndex />} />

            <Route path="car-bids/verified" element={<VerifiedCarBidLists />} />
            <Route path="car-bids/unverified" element={<UnverifiedCarBidLists />} />
            <Route path="car-bids/create" element={<CarBidFormIndex />} />
            <Route path="car-bids/edit" element={<CarBidFormIndex />} />
            <Route path="car-bids/sold" element={<SoldCarBidLists />} />

            <Route path="rentals/verified" element={<VerifiedRentalLists />} />
            <Route
              path="rentals/unverified"
              element={<UnverifiedRentalLists />}
            />
            <Route path="/rentals/create" element={<ProductRental />} />
            <Route path="/rentals/edit" element={<ProductRental />} />

            <Route path="/banners" element={<BannerLists />} />

            <Route path="/ads" element={<AdLists />} />

            <Route path="/users" element={<UserLists />} />
            <Route path="/dealers" element={<DealerLists isDealer={true} />} />
            <Route path="/direct-sellers" element={<DealerLists />} />
            <Route path="/marketing-emails/verified" element={<MarketingEmailList />} />
            <Route path="/marketing-emails/unverified" element={<UnverifiedEmailList />} />

            <Route path="/bidding-dealers" element={<BiddingDealerLists isDealer={true} />} />
            <Route path="/bidding-direct-sellers" element={<BiddingDealerLists />} />

            <Route path="/bidding-comments/verified" element={<CommentLists />} />
            <Route path="/bidding-comments/unverified" element={<UnverifiedCommentLists />} />
            <Route path="/bidding-comments/verified-bid-price" element={<BiddingPriceList />} />
            <Route path="/bidding-comments/unverified-bid-price" element={<UnverifiedBiddingPriceList />} />
            
            <Route path="/locations/regions" element={<RegionIndex />} />
            <Route path="/locations/regions/create" element={<RegionForm />} />
            <Route path="/locations/regions/edit" element={<RegionForm />} />

            <Route path="/locations/provinces" element={<ProvinceIndex />} />
            <Route path="/locations/provinces/create" element={<ProvinceForm />} />
            <Route path="/locations/provinces/edit" element={<ProvinceForm />} />

            <Route path="/locations/areas" element={<AreaIndex />} />
            <Route path="/locations/areas/create" element={<AreaForm />} />
            <Route path="/locations/areas/edit" element={<AreaForm />} />
            
            {/* CMS */}
            <Route path="/cms/announcement" element={<Announcement />} />

            <Route path="/cms/articles" element={<ArticleIndex />} />
            <Route path="/cms/articles/create" element={<ArticleForm />} />
            <Route path="/cms/articles/edit" element={<ArticleForm />} />

            <Route path="/cms/plate-numbers" element={<PlateNumberList />} />

            <Route path="/cms/car-workshops" element={<CarWorkShops />} />
            <Route path="/cms/car-workshops/create" element={<CarWorkshopForm />} />

            <Route path="/cms/brands" element={<BrandIndex />} />

            <Route path="/cms/packages" element={<PackagesIndex />} />
            <Route path="/cms/insurances" element={<InsuranceIndex />} />
            <Route path="/cms/about" element={<AboutIndex />} />

            <Route path="/cms/careers" element={<CareersIndex />} />
            <Route path="/cms/careers/create" element={<CareerForm />} />
            <Route path="/cms/careers/edit" element={<CareerForm />} />

            <Route path="/cms/faq" element={<FaqIndex />} />
            {/* End CMS */}

            <Route path="/trigger" element={<Trigger />} />
            <Route path="/banks" element={<BankIndex />} />

            <Route path="car-accessories/verified" element={<VerifiedAccessoriesList />} />
            <Route path="car-accessories/unverified" element={<UnverifiedAccessoriesList />} />
            <Route path="car-accessories/sold" element={<SoldAccessoriesList />} />
            <Route path="car-accessories/draft" element={<DraftAccessoriesList />} />
            <Route path="car-accessories/create" element={<AccessoryForm />} />
            <Route path="car-accessories/edit" element={<AccessoryForm />} />

            {/* <Route path="/insurances" element={<InsuranceIndex />} /> */}
            {/* <Route path="/packages" element={<PackagesIndex />} /> */}
            {/* <Route path="/brands" element={<BrandIndex />} /> */}
            {/* <Route path="/articles" element={<ArticleIndex />} />
            <Route path="/articles/create" element={<ArticleForm />} />
            <Route path="/articles/edit" element={<ArticleForm />} /> */}
            {/* <Route path="/careers" element={<CareersIndex />} />
            <Route path="/careers/create" element={<CareerForm />} />
            <Route path="/careers/edit" element={<CareerForm />} /> */}
            {/* <Route path="/plate-numbers" element={<PlateNumberList />} /> */}
            {/* <Route path="/coe" element={<CoeIndex />} />
            <Route path="/coe/create" element={<CoeForm />} />
            <Route path="/coe/edit" element={<CoeForm />} /> */}
            {/* <Route path="/faq" element={<FaqIndex />} /> */}
            {/* <Route path="/about" element={<AboutIndex />} /> */}
            {/* <Route path="car-workshops" element={<CarWorkShops />} />
            <Route path="/car-workshops/create" element={<CarWorkshopForm />} /> */}
            {/* <Route path="/announcement" element={<Announcement />} /> */}

            <Route path="/no-access" element={<NoAccessPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      ) : null}
    </div>
  );
}

export default App;
