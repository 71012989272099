import { Row, Col, Input, Select, Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import {
  addArea,
  editArea,
  fetchArea,

  fetchRegions,
  fetchProvinces,
} from "../../stores/api_calls/locations";
import "./location.scss";
import { error, success } from "../../contants/snackbars";
import {
  LoadingButton,
  PrimaryButton,
} from "../../custom_components/customButton";
import { IoMdAddCircleOutline , IoMdCloseCircle} from 'react-icons/io';
import { colorScheme } from '../../contants/variables';
import { useLocation, useNavigate } from 'react-router-dom';
import Kjur from '../../stores/utils/jwt'
import { validateAreaForm } from "../../stores/helpers/locationFormValidation";

const AreaForm = () => {
  
  const [isEditing, setIsEditing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [fetchLoading , setFetchLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [area, setArea] = useState({});
  const [form, setForm] = useState({
    areaName: "",
    regionId: "",
    provinceId: "",
  });

  useEffect(() => {
    handleFetchRegions();
    handleFetchProvinces();
  }, []);

  const handleFetchRegions = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getRegions = fetchRegions(filter);

    getRegions
      .then((res) => {
        if (res.data.success) {
          setRegions(res.data.data);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };
  
  const handleFetchProvinces = () => {
    const filter = {
      keyword: "",
      page: 1,
      limit: 500,
    };
    const getProvinces = fetchProvinces(filter);

    getProvinces
      .then((res) => {
        if (res.data.success) {
          setProvinces(res.data.data);
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleDropdownChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  useEffect(() => {
    if (location.pathname.includes("/edit")) {
      setIsEditing(true);
      const parsedData = Kjur.decode(params.get("token"));
      setFetchLoading(true)
      const getArea = fetchArea(parsedData?.id);
      getArea.then((res) => {
        if(res.data.success){
          setArea(res.data.data)
          setFetchLoading(false)
        }
      }).catch((e)=>console.log(e))
    }
  }, []);

  useEffect(()=>{
    if(!fetchLoading && isEditing) {
        const parsedData = Kjur.decode(params.get('token'))
        setForm({
          ...form,
          id: parsedData.id,
          areaName: parsedData.area_name,
          regionId: parsedData.region_detail.id,
          provinceId: parsedData.province_detail.id,
        })
    }
  }, [fetchLoading])

  const onSubmit = () => {
    setRequestLoading(true);

    const createArea = isEditing
      ? editArea(form)
      : addArea(form);

    createArea
      .then((res) => {
        if (res?.data?.success) {
          success(
            `Area ${isEditing ? "updated" : "added"} successfully`
          );
          setRequestLoading(false);
          navigate("../locations/areas", { replace: true });
        }
      })
      .catch((e) => {
        setRequestLoading(false);
        error(e.response.data.message);
      });
  };

  const onCancel  = () => {
    navigate("../locations/areas", { replace: true })
  }

  return (
    <div className="accessory-form-container">
      <div className={`table-title-icon ${!isEditing ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEditing ? "add" : "edit"}`}>
        {!isEditing ? "Create Area" : `Edit Area`}
      </p>

        <Row>
            <Col span={12}>
                <div className="form-fields">
                <p className="form-field-label">
                    Region <span>*</span> :{" "}
                </p>
                <Select
                    showSearch
                    allowClear
                    value={form.regionId || undefined}
                    onChange={(value) => handleDropdownChange("regionId", value)}
                    style={{ width: "100%" }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {regions.map((el, i) => (
                    <Select.Option key={i} value={el.id}>
                        {el.region_name}
                    </Select.Option>
                    ))}
                </Select>
                </div>
            </Col>
            <Col span={12}>
                <div className="form-fields">
                <p className="form-field-label">
                    Province <span>*</span> :{" "}
                </p>
                <Select
                    showSearch
                    allowClear
                    value={form.provinceId || undefined}
                    onChange={(value) => handleDropdownChange("provinceId", value)}
                    style={{ width: "100%" }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                    optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                >
                    {provinces.map((el, i) => (
                    <Select.Option key={i} value={el.id}>
                        {el.province_name}
                    </Select.Option>
                    ))}
                </Select>
                </div>
            </Col>
        </Row>

        <Row>
            <Col span={12}>
            <div className="form-fields">
                <p className="form-field-label">
                Area Name <span>*</span> :{" "}
                </p>
                <Input
                value={form.areaName}
                onChange={(text) => handleFieldChange("areaName", text)}
                placeholder="Area Name"
                />
            </div>
            </Col>
        </Row>

        <div className="btn-div">
            <Button
            color="default" 
            variant="solid"
            size="large"
            onClick={() => onCancel()}
            >
              Cancel
            </Button>

            <div className="btn">
            {requestLoading ? (
                <LoadingButton />
            ) : (
                <PrimaryButton
                label={isEditing ? "Update Area" : "Create Area"}
                color={colorScheme.primary}
                onClick={() => onSubmit()}
                disabled={!validateAreaForm(form)}
                />
            )}
            </div>
        </div>
    </div>
  );
};

export default AreaForm;