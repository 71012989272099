import { Input , Row , Col, Skeleton, Select, Divider } from 'antd';
import React , {useState , useEffect} from 'react';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import './articles.scss';
import {MdAddCircle} from 'react-icons/md';
import {FiSearch} from 'react-icons/fi'
import { RiEye2Line, RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri';
import {BsGearFill} from 'react-icons/bs'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import SectionForm from '../forms/articles/SectionForm';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import Kjur from '../stores/utils/jwt'
import { colorScheme } from '../contants/variables';
import { deleteArticle, fetchArticles } from '../stores/api_calls/articles';
import moment from 'moment'
import { error, success } from '../contants/snackbars';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState , convertFromRaw, convertToRaw} from "draft-js";
import ArticlePreviewModal from '../custom_components/articlePreviewModal';
import { NoData } from '../splashpages/SplashPages';

const ArticleIndex = (props) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [articles , setArticles] = useState([])
    const [sections , setSections] = useState([])
    

    const [filter ,setFilter] = useState({
        keyword:"",
        page:1,
        numOfArticlePerPage : 20,
        sectionId:"0"
    })

    const [fetchLoading , setFetchLoading] = useState(false)
    const [showModal , setShowModal] = useState({showPreview:false , showDelete:false , showSection:false, data:{}})
    const [searchText , setSearchText] = useState("")

    const [editData , setEditData] = useState({
        data : "",
        editorState : ""
    })
    
    useEffect(()=>{
        fetchData()
    },[])

    useEffect(()=>{
        if(filter){
            fetchData()
        } 
    },[filter])


    const fetchData = () => {
        const data = {
            keyword:searchText,
            page : filter.page,
            limit : filter.numOfArticlePerPage,
            sectionId:filter.sectionId
        }
        setFetchLoading(true)
        const getArticles = fetchArticles(data)
        getArticles.then((res) => {
            if(res.data.success){
                // console.log(res.data.data)
                setFetchLoading(false)
                setArticles(res.data.data)
            }
        }).catch((e)=>console.log(e))
    }

    const handleFieldChange = (name , e) => {
        setSearchText(e.target.value)
    }

    const handleDropdownChange = (name , value) => {
        setFilter({...filter , [name]:value})
    }

    const onAdd  = () => {
        navigate("../cms/articles/create", { replace: true })
    }

    const onEdit = (record) => {
        delete record.article_body;
        delete record.article_youtube_video;
        // console.log(record)
        const token = Kjur.encode(record)
        navigate(
            {
                pathname:'../cms/articles/edit',
                search:`?${createSearchParams({token:token})}`,
            },
            {replace:true}
        )
    }

    const onDeleteSubmit = () => {
        const removeArticle = deleteArticle(showModal.data._id)
        
        removeArticle.then((res)=>{
            if(res.data.success){
                console.log(res.data.data)
                const filteredData = articles.filter((el)=>el._id!==showModal.data._id)
                setArticles(filteredData)
                success("Section deleted successfully.")
                setShowModal({...showModal , showDelete : false , data:{}})
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onPreview = (item) => {
        const article = {
            articleTitle:item.article_title,
            articleAuthor:item.created_by,
            articleSection:item.article_section.section_name,
            articleTagsArray:item.article_tags,
            articleThumbnail:item.article_thumbnail
        }

        const contentState = convertFromRaw( item.article_body );

        const newContent = EditorState.createWithContent(contentState)

        setEditData({
            ...editData,
            data:article,
            editorState : newContent
        })

        setShowModal({...showModal , showPreview:true})
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            fetchData()
        }
    }


    return (
        <div className='article-container'>
            <div className='article-header'>
                <div className='article-filter'>
                    <div className='search-field-div'>
                        <Input
                            prefix={<FiSearch/>}
                            size='large'
                            placeholder='Search Record'
                            value={searchText}
                            onChange={text=>handleFieldChange('search' , text)}
                            onKeyPress={onEnter}
                        />
                    </div>
                    <div className='search-field-div'>
                        <Select
                            size='large'
                            showSearch
                            value = {filter.sectionId || undefined}
                            onChange={(value)=>handleDropdownChange('sectionId' , value)}
                            style={{ width: '100%' }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {sections.map((el,i)=>
                                <Select.Option key={i} value={el._id}>
                                    {`Section : ${el.section_name}`}
                                </Select.Option>
                            )}
                        </Select>
                    </div>
                </div>
                <div className='article-btn'>
                    <div className='btn-div'>
                        <PrimaryButton
                            Icon={<MdAddCircle/>}
                            label="Add New Article"
                            color={colorScheme.primary}
                            onClick={()=>onAdd()}
                        />
                    </div>
                    <div className='btn-div'>
                        <PrimaryButton
                            Icon={<BsGearFill/>}
                            label="Manage Sections"
                            color={colorScheme.primary}
                            onClick={()=>setShowModal({...showModal, showSection:true})}
                        />
                    </div>
                </div>
                
            </div>

            <div className='article-card-div'>
                <Row gutter={[12,12]}>
                    {fetchLoading?
                        [...Array(4)].map((_,i)=>
                            <Col key={i} span={6}>
                                <div className='article-card-container' style={{padding:"20px"}}>
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            </Col>
                        )
                    :!fetchLoading && articles.length?
                        articles.map((item,idx)=>
                            <Col span={6} key={idx}>
                                    <div className='article-card-container'>
                                        <div className='article-card-header'>
                                            {item.article_youtube_video ?
                                                <iframe 
                                                width="100%" 
                                                height="150"
                                                src={`https://www.youtube.com/embed/${item.article_youtube_video.split("v=")[1]}`} 
                                                title="YouTube video player" 
                                                frameborder="0" 
                                                allow="accelerometer; 
                                                autoplay; 
                                                clipboard-write; 
                                                encrypted-media; 
                                                gyroscope; 
                                                picture-in-picture; 
                                                web-share" allowfullscreen></iframe>
                                                :
                                                <img src={item.article_thumbnail}/>
                                            }
                                            
                                            <div className='section'>
                                                <p>{item?.article_section?.section_name ?? "No section"}</p>
                                            </div>
                                        </div>

                                        <div className='article-card-body'>
                                            <p className='title'>{item.article_title}</p>
                                            <p className='date'> {item.created_by} | {moment(item?.date_created).format('LL')}</p>
                                            {/* <p className='introduction'>{item.introduction}</p> */}
                                        </div>

                                        <Divider style={{margin:"5px 0px"}}/>

                                        <div className='article-card-footer'>
                                            <IconButton 
                                                className='icon-button view' 
                                                tootTipTitle="View Article" 
                                                Icon={<RiEye2Line/>} 
                                                onClick={()=>onPreview(item)} 
                                            />
                                            <IconButton 
                                                className='icon-button edit' 
                                                tootTipTitle="Edit Article" 
                                                Icon={<RiEdit2Fill/>} 
                                                onClick={()=>onEdit(item)} 
                                            />
                                            <IconButton 
                                                className='icon-button delete' 
                                                tootTipTitle="Delete Article" 
                                                Icon={<RiDeleteBin2Fill/>} 
                                                onClick={()=>setShowModal({...showModal, showDelete:true , data:item})}
                                            />
                                        </div>
                                    </div>
                            </Col>
                        )
                    :<NoData message="NO ARTICLE DATA FOUND"/>
                    }
                </Row>
            </div>

            <ArticlePreviewModal
                visible = {showModal.showPreview}
                form = {editData.data}
                editorContent = {editData.editorState}
                onCancel = {()=>setShowModal({...showModal , showPreview:false , data:{}})}
            />

            <SectionForm
                visible={showModal.showSection}
                onCancel={()=>setShowModal({...showModal , showSection:false , data:{}})}
                sections={sections}
                setSections={setSections}
            />

            <CustomDeleteModal
                title="Delete Article"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.title}` , type:"article"}}
            />

        </div>
    )
}

export default ArticleIndex
